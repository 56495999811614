aside.sidebar {
  @media (max-width: $screen-lg - 1) {
    padding-left: 0;
  }
  @media (max-width: $screen-md-max - 1) {
    padding-right: 0;
  }
  @media (max-width: $screen-xs-max) {
    padding: 3rem;
  }
  .sidebar-wrapper {
    
    width: 100%;
    margin-top:-50%;
    @media (max-width: $screen-lg - 1) {
      margin-top: -57%;
    }
        
    @media (max-width: $screen-md-max) {
      margin-top: -67%;
    }
    
    @media (max-width: $screen-xs-max) {
      margin-top: 0%;
    }
    
    .sidebar-top, .sidebar-bottom {
      
      background-color: color('yellow');
      display: inline-block;
      margin: 0;
      width: 100%;
      height: 65px;
    }
    .sidebar-top {
      
      border-radius: 50% 50% 0 0/ 100% 100% 0px 0px;
      
      h3 {
        color: color('white');
        font-style: italic;
        @include font-size(20);
        margin-top: 30px;
      }
    }
    
    > ul {
      
      background-color: color('white');
      margin-bottom:0px;
      min-height: 200px;
      
      @media (max-width: $screen-xs-max) {
        text-align:center;
      }

      li {
        
        list-style-type: none;
        border: 0;
        border-bottom: 2px solid color('yellow');
        border-style: dotted;
        padding: 0;
                
        a {
          @include font-size(16);
          @include line-height(50);
          font-style: italic;
          color: color('green');
          padding-left:1rem;
          
        }
      }
    }
    
    .sidebar-bottom {
      border-radius: 0 0 50% 50% / 0px 0px 100% 100%;
      margin-bottom:20px;
    }
    
    .sidebar-address, .sidebar-phone {
      
      margin-top:10px;
      margin-bottom:10px;
      
      text-align: center;
      a {
        
        color: color('brown');
        @media (max-width: $screen-xs-max) {
          br {
            display: none;
          }
        }
        
      }
    }
    .sidebar-address {
      @include font-size(18);
    }
    .sidebar-phone {
      
      @include font-size(26);
      
      .fa-phone {
        color: color('yellow');
        margin-right:5px;
      }
    }
    .sidebar-business-hours {
      
      @include font-size(18);
      text-align: center;
      
      h4 {
        color: color('brown');
        font-weight: bold;
        @include font-size(18);
        margin-top: 3rem;
        margin-bottom: 10px;
      }
      ul {
        li {
          list-style-type: none;
          padding-bottom: 0;
          @media (max-width: $screen-lg - 1) {
           @include font-size(17);
          }
        }
      }
    }
    .sidebar-links {
      
      margin-top: 30px;
      margin-bottom: 20px;
      
      .btn-outter.btn-large {
        
        height: 7rem;
        
        .btn.round {
          line-height:5rem;
        }
      }
    }
  }
  
}
  