
.footer {
    background: $brand-primary;
    width: 100%;
    
    .footer-top {
        
        .footer-nav {
            text-align: center;
            
            ul {
                margin-bottom: 0;
                padding-top:1rem;
                > li {
                    display: inline-block;
                    
                    a {
                        font-family: 'Open Sans', sans-serif;
                        text-decoration: none;
                        padding-left: 6px;
                        padding-right: 6px;
                        color: color('red');
                        text-transform: uppercase;
                        font-size: 14px;
                        
                        &:hover {
                            color: color('white');
                        }
                    }
                    
                    + li {
                        
                    }
                    
                    &.current-menu-item {
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        
        .footer-social {
            a {
                text-align: center;
                color: color('foggray');
                display: inline-block;
                margin: 0 6px;
                padding: 5px;
                
                i {
                    font-size: 24px;
                }

                &:hover {
                    color: color('white');
                    -webkit-animation-duration: 1s; 
                    animation-duration: 1s; 
                    -webkit-animation-fill-mode: both; 
                    animation-fill-mode: both;
                    -webkit-animation-name: pulse; 
                    animation-name: pulse; 
                }
            }
        }
        
        .footer-contact {
            padding-top: 5px;
            color: color('foggray');
            text-align: center;
            
            p {
                font-family: 'Open Sans', sans-serif;
                font-size: 1rem;
            }
        }
    }
    
    .footer-bottom {
        color: color('white');
        font-size: 10px;
        background: color('grayDark');
        
        a {
            color: color('red');
            
            &:hover {
                color: color('white');
                text-decoration: none;
            }
        }
    }
}


