
#menu-header {
    margin: 3rem 1rem;
    display: flex;
    align-items: stretch;
    float:right;
    &.home {
        float:none;
        text-align:center;
    }
    
    @media(max-width:767px){
        float:left;
        display:block !important;
        //margin: 7rem 0 !important;
        width:100%;
        background:color('red');
        margin-top: 30px !important;

    }
    @media (max-width: 992px) {
        //margin:2rem 0rem !important;
    }

    > li:not(.ticket-button)  {
        flex: 0 1 auto;
        list-style-type: none;
        > a{
            @include font-size(18);
            text-transform: uppercase;
            font-family: $nav-font-family;
            font-weight: 400;
            color:color('black');
            .caret {
                display: none;
            }
            @media(min-width:767px) {
             &:focus, &:hover + .dropdown-menu{
               // @media(min-width:767px) {
                    //display: block;
                //}
            }
            }
                        @media(max-width:767px) {
             &:focus + .dropdown-menu{
               // @media(min-width:767px) {
                    //display: block;
                //}
            }
            }
        }
        
        //drop down menu, if there is one
        .dropdown-menu {
            //display: none;
            background-color: rgba(82, 111, 128,.8);
            padding-top: 0;
            padding-bottom: 0;
            
            > li {
                
                > a {
                    color:color('red');
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 14px;
                    
                    &:hover {
                        background-color: color('red');
                    }
                }
            }
            
            > .active > a {
                background-color: color('darkblue');
            }
            
            &:hover {
                display: block;
            }
        }
    }
}


.header {
    position: relative;
    background-color:transparent;
    margin-bottom: 0;
    border-bottom:none;
    @media (max-width: 768px) {
      a, i {
        font-size: 2.5rem;
      }
    }
    //Default Header Container
    .container {
      padding-top:0;
      padding-bottom:0;
      @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .header_top {
      padding: 0.5rem 0;
      background:color('opaquegray');
      margin:0;
      position: relative;
      height: $navbar-top-height;

      .container {
          color:color('grayLight');
          
          .company_info_col {
              a {
                  color:color('grayLight');
              }
              
              .locations {
                  
                  cursor: pointer;
                 
                  
/*                  &:focus, &:hover + .addresses {
                      display: block;
                  }*/
                
              }
              @media(max-width:767px) {
                    display:inline-flex;
               }
               
              .addresses {
                display:none;
                font-size: 1.2rem;
                position: absolute;
                top: 15px;
                padding: 1rem;
                width: 90%;
                margin-left: 25%;
                z-index: 100;
                color: #000;
                background-color: color('white');
                color:color('black');
                @media(min-width:767px) {
                    &:hover {
                        //display: block;
                    }
                }
                button {
                    padding:.5rem 1rem;
                    color:color('green');
                    margin-top:1rem;
                }
              }
              
          }
        
        .top-left {
            text-align: left;
            @include font-size(18);
            
            .social-icons {
              width: 223px;
              text-align: center;
              
              a {
                i {
                  padding-right:10px;
                  padding-left:10px;
                  @media (max-width: 768px) {
                    padding-right:5px;
                    padding-left:5px;
                  }
                }
                
              }
            }
            
            
        }
        .top-right {
          
          text-align: right;
          @include font-size(20);
          padding-right: 25px;
          
          i {
            color: color('red');
            margin-right:10px;
            
          }
            
          a {
            letter-spacing: 2px;
            font-weight:bold;
          }
            
        }
      }
    }
    
       .header_bottom {
      min-height:125px;
      background-color:transparent;
      height: $navbar-height;
      border: 0px;
      
      .container   {   
          a {
              justify-content: space-between;
              color:color('red');

          }
        .navbar_header {
          background-color:transparent;
          @media (max-width: 768px) {
            padding-left: 6px;
            padding-right: 6px;
            width:100%;
          }
          margin-left: 0;
          margin-right: 0;
          // Toggle in Mobile
   
          //logo
          .navbar-brand {
            
            margin-left: 0;
            padding: 0;
            padding-right:0;

            img {
              padding:0 10px 10px 10px;
              background-color: color('green');
              border-bottom-right-radius: 54%;
              border-bottom-left-radius: 54%;
              position: relative;
              z-index:10;
              width:auto !important;
            }

          }
        

        @media (max-width: 768px) {
          .navbar-collapse {
            width: 100%;
            background: color('yellow');
            margin-left: 0;
          }
        }
          
        div {
            .navbar-collapse {
                //actual menu text <ul>
                @media(max-width:767px) {
                float:left;
                width: 100% !important;
                padding-right:0;
            }
        }
        }

    }
  }  
}
}

body:not(.home) {
    .header {
         .header-fade {
            background: -webkit-gradient(linear, left top, left bottom, color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1)));
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
            background: -o-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
            background: -ms-linear-gradient(top, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 100%);
            height: 200px;
            margin-top: -35px;            
        }
    }
}

    .header {
        
        .header_top {
            border-bottom: none;
            border-bottom: none;
            width: auto;
            .top-left, .top-right, .top-middle {
                text-align: center;
            }
            .container {
                .company_info_col {
                    .location-link {
                        @media(min-width:767px) {
                            display:none;
                        }
  
                    }
                    .company_info_text {
                        @media(max-width:767px) {
                            display:none;
                        }
                    }
                    .locations {
                        @media(max-width:767px) {
                            display:none;
                        }   
                    }
                }
/*                .mobile_company_info_col {
                    display:none !important;
                  @media(max-width:767px) {
                            display:none;
                    }  
                }*/
            }
        }
        
    }
/*    .navbar-default .navbar-collapse {
        width:100%;
        padding:0;      
    }*/
    

            .navbar-default .navbar-toggle {
              background-color: $navbar-default-toggle-bg;
              margin-top:40px !important;
            }
.home {
    .header {
            width: 100%;
            background: transparent;
            position: absolute;
            @media(max-width:767px){
                position:relative;
            }
                        .home-menu {
                            float:left;
            nav {

                #menu-header>li:not(.ticket-button)>a {
                    color:color('white') !important;
                }
                
            }

        }
    }
}

.navbar-collapse.in {
    overflow-y: initial;
}
@media(max-width:767px) {
    #menu-header:not(.home) {
        margin: 0;   
    }
    #menu-header>li:not(.ticket-button)>a {
        color:color('white');
    }
}

        #home_mobile_logo {
            @media(min-width:767px) {
                display:none;
            }
        }
        
        .home_big {
            @media(min-width:767px) {
                display:none !important;
            }
        }        
        .home_mobile {
            @media(max-width:767px) {
                display:none !important;
            }     
        }
        
        .home .navbar-brand>img {
            width:auto;
            @media(min-width:767px) {
                display:none;
            }
        }
                    .home #menu-header {
                        float:none !important;
                        display: -webkit-inline-box;
                        @media(max-width:767px) {
                            float:left !important;
                        }
                        li:not(.ticket-button)>a {
                        color:color('white') !important;
                        @media(min-width:767px) {
                            width: 100%;
                            background: transparent;
                        }
                        @media(max-width:767px){
                            position:relative;
                            text-align:left !important;
                        }
                    }
                
                    }
                
             