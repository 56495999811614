//**
// All Variables Used Throughout
//**
// These comments won't appear in compiled code so use these as much as possible.
//* The other comments if you remove one "/" from the beginning appear in compiled code (Use Sparingly)
// Glyphicons font path
$icon-font-path:        "../fonts/";
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
// Add an extra responsive for extra large screen resolutions
$screen-xxs: 320px;
$screen-xxs-min: ($screen-xxs - 1);
// Add an extra responsive for extra large screen resolutions
$screen-xl: 1366px;
$screen-xl-min: ($screen-xl - 1);
// Add an extra responsive for extra extra large screen resolutions
$screen-xxl: 1920px;
$screen-xxl-min: ($screen-xxl - 1);
//== Colors
//
//* this is called a SASS map and can be used in code as .bg-white and .text-white
$mainColors: (
  'white': #ffffff,
  'black': #000000,
  'red': #c74735,
  'orange':#ff6633,
  'yellow': #dcb450,
  'brown': #564800,
  'green': #8EA650,   
  'blue':  #5f869c,  
  'darkblue': #526f80,
  'gray':  #777777,
  'grayLighter': #eee,
  'grayLight': #bfbfbf,
  'grayDark': #333333,
  'grayDarker': #222222,
  'opaquegray':#535b5f,
  'foggray':#3d3f40,
);
// build .text-color and .bg-color classes
@each $name, $value in $mainColors {
  @include text-color($name, $value);
  @include bg-color($name, $value);
}
//## Gray and brand colors for use across Bootstrap.
$brand-primary:         color('blue');
//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            color('grayDarker');
//** Global textual link color.
$link-color:            color('red');
//== Typography (COPIED FROM BOOTSTRAP VARIABLES)
//
//## Font, line-height, and color for body text, headings, and more.
//h1
$h1-font-size: 1rem;
$h1-font-family: "Montserrat", sans-serif;
$h1-font-color: color('black');
$h1-line-height: 3.75rem;
$h1-text-transform: uppercase;
$h1-font-weight: 700;
//h2
$h2-font-family: "Montserrat", sans-serif;
$h2-color: color('black');
$h2-font-size: 3rem;
//h3
$h3-font-family: "Montserrat", sans-serif;
$h3-font-weight: 500;
$h3-color: color('black');
$h3-font-size: 1.375rem;
$h3-line-height: 1.5rem;
$h3-text-transform: uppercase;
//p
$p-font-family: inherit;
$p-font-weight: normal;
//$p-font-size: 1rem;
$p-line-height: 1.6;
$p-margin-bottom: 1.25rem;
$small-text:14.4px;
$p-text-rendering: optimizeLegibility;
$font-family-sans-serif:   "Montserrat", sans-serif;
$font-family-serif:       "Montserrat", sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   "Montserrat", sans-serif;
$font-family-base:        $font-family-sans-serif;
$base-font-size:          18;
$h1-font-size:            40;
$h2-font-size:            28;
$h3-font-size:            20;
$base-line-height:        1.6em;
$base-pad-size:           20;
$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px
$headings-font-family:    inherit;
$headings-font-weight:    400;
$headings-line-height:    1.1;
$headings-color:          color('black');
$headings-color-primary:          color('black');
$headings-color-secondary:          $text-color;
//== Navbar 
//
$navbar-height:                    50px;
$navbar-top-height:                40px;
$navbar-bottom-height:             80px;
$navbar-default-bg:                transparent;
$navbar-default-border:            darken($navbar-default-bg, 6.5%);
$navbar-top-border:                #b3bbc9;
$navbar-top-bg:                    color('gray');
// Navbar links
$navbar-default-link-color:                color('white');
$navbar-default-link-hover-color:          color('white');
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         color('white');
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color:       darken($navbar-default-link-color, 10%);
$navbar-default-link-disabled-bg:          transparent;
// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;
// Navbar toggle
$navbar-default-toggle-bg:                 $navbar-top-bg;
$navbar-default-toggle-hover-bg:           darken($navbar-top-bg, 6.5%);
$navbar-default-toggle-icon-bar-bg:        $navbar-default-link-color;
$navbar-default-toggle-border-color:       $brand-primary;
//=== Shared nav styles
$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         $navbar-default-link-hover-color;
$nav-font-size:                             1.75rem;
$nav-font-family:                           inherit;
/*
 * => FONT VARS
 * ---------------------------------------------------------------------------*/
 // Declare your fonts in _fonts.scss
 
 //== Navbar 
//
$footer-bg-color: color('yellow');
$footer-top-bg-color:     color('green');
$footer-default-text-color:                color('white');
$footer-default-link-color:                color('white');
$footer-default-font-size:     12;
$footer-heading-font-size:     16;
$footer-bottom-height: 65px;
