// return color value from given key
@function color($key) {
  @if map-has-key($mainColors, $key) {
    @return map-get($mainColors, $key);
  }
  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

@function rem-sizing($target-size){
		@return ($target-size / 10);
}

@mixin font-size($font-size: $base-font-size) {
	font-size: ($font-size) + px;
	font-size: rem-sizing($font-size) + rem;	
}

@mixin line-height($line-height: $base-line-height) {
	line-height: ($line-height) + px;
	line-height: rem-sizing($line-height) + rem;	
}

@mixin text-padding($text-padding: $base-pad-size, $pad2: false, $pad3: false, $pad4: false) {
	@if $pad4 {	
		padding: ($text-padding) + px ($pad2) + px ($pad3) + px ($pad4) + px;
		padding: rem-sizing($text-padding) + rem rem-sizing($pad2) + rem rem-sizing($pad3) + rem rem-sizing($pad4) + rem;
	} @else if $pad3 {
		padding: ($text-padding) + px ($pad2) + px ($pad3) + px;
		padding: rem-sizing($text-padding) + rem rem-sizing($pad2) + rem rem-sizing($pad3) + rem;
	} @else if $pad2 {
		padding: ($text-padding) + px ($pad2) + px;
		padding: rem-sizing($text-padding) + rem rem-sizing($pad2) + rem;
	} @else {
		padding: ($text-padding) + px;
		padding: rem-sizing($text-padding) + rem;
	}
}

@mixin padding($side, $padAmount: $base-pad-size) {
	padding-#{$side}: ($padAmount) + px;
	padding-#{$side}: rem-sizing($padAmount) + rem;	
}
// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}
/*
 * => TEXT AND BG CLASSES
 * ---------------------------------------------------------------------------*/
// Text Colors
@mixin text-color($name, $value) {
	.text-#{$name} {
		color: $value;
		p,
		h1, .h1,
		h2, .h2,
		h3, .h3,
		h4, .h4,
		h5, .h5,
		h6, .h6 {
			color: $value;
		}
	}
}


// Background Colors
@mixin bg-color($name, $value) {
	.bg-#{$name} {
		background-color: $value;
	}
}



