/*.request_consultation_btn {
    background: transparent !important;
    font-family: 'Open Sans', sans-serif !important;
    border: 2px solid color('white') !important;
    color: color('white') !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    
    &:hover {
        background-color: color('green') !important;
        border-color: color('green') !important;
        -webkit-transition: background-color .3s ease-out;
        -moz-transition: background-color .3s ease-out;
        -o-transition: background-color .3s ease-out;
        transition: background-color .3s ease-out;
    }
}

.gform_button {
        cursor: pointer;
    padding: 1em 1.4em;
    margin: 0 0.1em 0.5em 0.2em;
    position: relative;
    line-height: 1 !important;
    font-style: normal !important;
    vertical-align: middle;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-shadow: none !important;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    letter-spacing: 0;
    color: white !important;
    width: auto;
    border: 2px solid #c74735;
    background: #c74735;
    border-radius: 4px;
    outline: none;
    margin: 0 15px 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
:not(#consultation_form) &.gform_button {
    color:color('green');
}
button, .fl-button-wrap {
    border: 2px solid #8ea650;
    padding: 1rem 2rem;
    background: transparent;
    font-family: Open Sans,sans-serif;
    text-transform: uppercase;
    border-radius: 0;
    &:hover {
        -webkit-transition: background-color .3s ease-out;
        -moz-transition: background-color .3s ease-out;
        -o-transition: background-color .3s ease-out;
        transition: background-color .3s ease-out;
        color:color('white');
        background:color('green');
    }
}*/
.ticket-button,.woocommerce ul.products li.product .button,.woocommerce div.product form.cart .button, #place_order, .woocommerce a.button.alt {
    cursor: pointer;
    margin: 0 0.1em 0.5em 0.2em;
    position: relative;
    line-height: 1 !important;
    font-style: normal !important;
    vertical-align: middle;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-shadow: none !important;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    letter-spacing: 0;
    color: white !important;
    width: auto;
    border: 2px solid #c74735;
    background: #c74735;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color:color('white') !important;
}
.about-button {
    cursor: pointer;
    margin: 0 0.1em 0.5em 0.2em;
    padding: 0.7em 1.5em;
    position: relative;
    line-height: 1 !important;
    font-style: normal !important;
    vertical-align: middle;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-shadow: none !important;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: 700;
    text-decoration: none !important;
    display: inline-block;
    letter-spacing: 0;
    color: white !important;
    width: auto;
    border: 2px solid #c74735;
    background: #c74735;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color:color('white') !important;
}

.woocommerce a.button.alt:hover {
    color:color('red') !important;
    background:color('white') !important;    
}

.simcal-default-calendar-dark .simcal-nav-button {color:color('black');}



.woocommerce div.product form.cart .button {
    margin-top: 3px;
}