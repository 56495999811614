/*
 * => GENERAL
 * ---------------------------------------------------------------------------*/
input {
  padding: 5px;
  margin-top:5px;
}  

/*
 * => SEARCH FORM
 * ---------------------------------------------------------------------------*/

.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

/*
 * => GRAVITY FORMS
 *
 * Turn OFF "Output CSS" in GF settings, and turn ON HTML 5
 *
 * ---------------------------------------------------------------------------*/

.gform_wrapper ul {
  @extend .list-unstyled;
}

.gform_wrapper li {
  @extend .form-group;
}

.gform_wrapper form {
  margin-bottom: 0;
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $state-danger-text;
}

.ginput_container input,
.ginput_container select,
.ginput_container textarea {
  @extend .form-control;
  background-color: white;
}

.ginput_container textarea {
  height: auto;
}

.gform_heading .gform_title {
    display: none;
}

.gform_wrapper {
    
    .gform_body {
        
        .gfield_label {
            font-size: 12px;
            margin-bottom: 0;
            
            .gfield_required {
                color: color('white');
            }
        }
        
        .ginput_container_text {
            font-size: 14px;
        }
        
        .ginput_container input {
            height: 40px;
            font-size: 14px;
        }
        
    }
    
    .gform_footer {
        margin: 0 0 0 0 !important;
    }
    
}

.gform_wrapper ul li.gfield {
    margin-top: 0 !important;
}

.gform_button {
  @extend .btn;
  @extend .btn-primary;
}

// Hidden labels
.hidden_label .gfield_label {
  @extend .hidden;
}

// Errors and Validation

.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }
  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    @include form-control-focus($alert-danger-text);
  }
}
.validation_error {
  @extend .alert;
  @extend .alert-danger;
}
#gforms_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_confirmation_message {
    min-height: 200px;
    text-align: center;
}

// Hide the ajax spinner
.gform_ajax_spinner {
  display: none;
}

// Hide honeypot
.gform_validation_container {
  display: none;
  position: absolute;
  left: -9000px;
}

// Checkboxes
.gfield_checkbox {
  input[type=checkbox] {
    width: 1em;
    height: .95em;
    display: inline;
    border: none;
    box-shadow: none;
  }
}

//  Footer fix
.gform_footer {
  clear: both;
}

input[type="text"], .woocommerce-checkout #payment div.payment_box input.js-sv-wc-payment-gateway-credit-card-form-input[type="tel"] {
    border: solid 1px #ccc !important;
    background-color: rgba(255,255,255,0.75) !important;
    border-radius:0;
    box-shadow:none;
}
.gfield_label {
    font-weight: 500;
    font-size: 1.2rem;
}


// READY CLASSES & Media

/*@include media('>mobileBreakpoint') {
  .gform_wrapper {

    // 2 columns
    .gf_left_half {
      margin: 0 0 8px 0;
      width: 49%;
      float: left;
      clear: left;
    }
    .gf_right_half {
      margin: 0 0 8px 0;
      width: 49%;
      float: right;
      clear: right;
    }

    // 3 columns
    .gf_left_third,
    .gf_middle_third,
    .gf_right_third {
      margin: 0 0 0.50em 0;
      width: 32%;
    }
    .gf_left_third {
      float: left;
      clear: left;
    }
    .gf_middle_third {
      float: left;
      clear: none;
      margin-left: 2%;
    }
    .gf_right_third {
      float: right;
      clear: right;
    }

    // for complex parts, such as addresses
    .ginput_left {
      width: 49%;
      float: left;
      clear: left;
    }
    .ginput_right {
      width: 49%;
      float: right;
      clear: right;
    }
    .gf_clear, .gf_clear_complex {
      clear: both;
    }

    // lists
    .gf_list_inline ul.gfield_checkbox li, 
    .gf_list_inline ul.gfield_radio li {
      width: auto !important;
      float: none !important;
      margin: 0 0.625em 0.625em 0;
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
    }
  }
}*/

.woocommerce div.product form.cart .variations label {
    color: #000000;
    font-size: 3rem;
    padding: 1rem 0rem;
}
.woocommerce div.product form.cart .variations td {
    vertical-align:middle;
}
.checkbox input[type=checkbox] {
    position:relative !important;
}
.woocommerce-checkout #payment div.payment_box {
    background:#ffffff;
    border: #DFDCDE 5px solid;
}

.woocommerce-checkout #payment ul.payment_methods {
    background:#ffffff;
}
#add_payment_method #payment div.payment_box::before, .woocommerce-cart #payment div.payment_box::before, .woocommerce-checkout #payment div.payment_box::before {
    top:-1em;
}
.woocommerce-checkout #payment div.form-row {
    background:#fff;
}