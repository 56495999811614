
.featured-row {
    background:color('red');
    .featured-container {
        .featured-service {
            h4 {
                color: #fff;
                font-size: 1.5rem;
                text-shadow: 1px 1px 2px #3c3f40;
            }
        }
    }
}
.simcal-event-title {font-size:1.5rem;}
.simcal-default-calendar .simcal-current h3 {font-size:3rem; font-weight: 700;}


//woocommerce
.woocommerce ul.products li.product .price, .woocommerce-loop-product__title {
    color: #000;
    font-weight:700;
}
.woocommerce #content div.product .woocommerce-tabs, .woocommerce-page #content div.product .woocommerce-tabs, .woocommerce-page div.product .woocommerce-tabs, .woocommerce div.product .woocommerce-tabs {
    clear: right;
    float:right;
    width: 100%;
    @media(min-width:767px) {
        width:50%;
    }
}
.woocommerce div.product div.images img {
    width:auto;
    margin:auto;
    padding:25px 10px;
}
.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
    text-align:center;
}

.cart-contents:before {
    font-family:fontawesome;
    content: "\f07a";
    font-size:28px;
    margin-top:10px;
    font-style:normal;
    font-weight:400;
    padding-right:5px;
    vertical-align: bottom;
}
.cart-contents:hover {
    text-decoration: none;
}
.cart-contents-count {
    color: #fff;
    background-color: #2ecc71;
    font-weight: bold;
    border-radius: 10px;
    padding: 1px 6px;
    line-height: 1;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: top;
}
.cart-icon {text-align:right;}
.cart-contents {color:color('black');}
.woocommerce div.product form.cart .variations td.label {
    display:none;
}
.fc-day-top {
  background: #000 !important;
  color: #fff;
  text-align: center !important;
  span.fc-day-number {
    float:none !important;
  }
}
.fc-day-number {text-align:center;}

.fc-unthemed td.fc-today {
  border-color:#c74735 !important;
  background:none !important;
}
td.fc-day-top.fc-today  {
  background-color:#c74735 !important;
}
.fc-toolbar h2 {
  font-weight: 700 !important;
  margin-top: 7px !important;
}
