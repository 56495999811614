@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
/*.metaslider .msHtmlOverlay .layer .content {
    overflow:inherit !important;
}
.msHtmlOverlay {
    display:inherit !important;
}*/
* {
    margin: 0;
    padding: 0;
}

html{font-size: 45%;}

body{
  text-rendering:optimizeLegibility;
  overflow-x:hidden;
}

h1{
    @include font-size($h1-font-size);
    line-height: $h1-line-height;
    color: $h1-font-color;
    text-transform: uppercase;
    font-weight: 700;
}
.h1-heading {

}
h2{
    @include font-size($h2-font-size);
    @include text-padding(0,0,10);
    color: $h2-color;
    font-family:$font-family-serif;
}
.h2-heading {

}
h3{
    @include font-size($h3-font-size);
    font-weight: $h3-font-weight;
}
.h3-heading {

}
p{
    @include font-size($base-font-size);
    line-height: $base-line-height;
    font-size: 16px;
}

li{
    @include font-size($base-font-size);
    list-style-position: inside;
    font-size: 16px;
    padding-bottom: 0px !important;
}


.page-header {
    border-bottom: 1px solid #ddd;
    max-width:75rem;
    margin:auto;
}

.container{
  @include text-padding(10,10);
}

section{
  border-top:1px solid black;
  border-bottom:1px solid #ddd;
  @include text-padding(30);
}
.fa {
    margin: 0 .5rem;
}
a:hover {
    color:color('red');
    text-decoration:none;
}

.inner-main-image > .img-responsive {
        min-width:100%;
}

.dark-background {
    background: #333;
}
